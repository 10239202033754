import React, { useState, useRef, useEffect } from "react";
import "./FeedBack.css";
import axios from "axios";
import FeedBackS1Img from "../../Assets/images/FeedBack/FeedBackS1Img.webp";
import FeedBackS1ImgMob from "../../Assets/images/FeedBack/FeedBackS1ImgMob.webp";
import HomeS3Right from "../../Assets/svg/Home/HomeS3Right.svg";
import { Link } from "react-router-dom";
import HomeS3First from "../../Assets/images/Home/HomeS3First.webp";
import HomeS3Second from "../../Assets/images/Home/HomeS3Second.webp";
import HomeS3Third from "../../Assets/images/Home/HomeS3Third.webp";
import Select from "react-select";
import GrayStar from "../../Assets/svg/FeedBack/StarGrayFB.svg";
import GoldStar from "../../Assets/svg/FeedBack/StarGoldFB.svg";
import useIsMobile from "../../Assets/hooks/Reuse_Component/IsMobile/IsMobile";
export default function FeedBackContent() {
  return (
    <>
      <div className="FeedBackMain">
        <FeedBackS1 />
        <FeedBackS2 />
        <FeedBackS3 />
      </div>
    </>
  );
}

function FeedBackS1() {
  const isMobile = useIsMobile();

  return (
    <>
      <div className="FeedBackS1">
        <div className="FeedBackS1P1">our Feedback</div>
        <div className="FeedBackS1P2">Your input is greatly appreciated</div>
        <div className="FeedBackS1P3">
          <img src={isMobile ? FeedBackS1ImgMob : FeedBackS1Img} alt="car wash center FEDBCK" className="FeedBackS1P3Img" />
          <div className="FeedBackS1P3V">Your Feedback Fuels Our Growth</div>
        </div>
        <div className="FeedBackS1P4">
          We value your feedback to help us improve our services and products. Please take a moment to share your thoughts and suggestions.
        </div>
        <div className="FeedBackS1P5">Wash your stuff team</div>
      </div>
    </>
  );
}

function FeedBackS2() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 575);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 575);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      border: "none",
      borderRadius: "5px",
      padding: "0.5vw 1vw",
      fontSize: isMobile ? "3.5vw" : "1vw",
      backgroundColor: "#f0f3fa",
      fontFamily: "SanDiego",
      color: "#5b7a99",
      boxShadow: "none",
      width: isMobile ? "80vw" : "50vw",
      "&:hover": {
        borderColor: "#5b7a99",
      },
    }),
    placeholder: (base) => ({
      ...base,
      fontFamily: "SanDiego",
      fontSize: isMobile ? "3.5vw" : "1vw",
      color: "#5b7a99",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#5b7a99",
      "&:hover": {
        color: "#5b7a99",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "5px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      overflow: "hidden",
      backgroundColor: "#f0f3fa",
      padding: "0.5vw 1vw",
      zIndex: 1000,
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#f0f3fa" : state.isFocused ? "#f0f3fa" : "#f0f3fa",
      color: "#333",
      padding: "10px",
      cursor: "pointer",
      fontFamily: "SanDiego",
      fontSize: isMobile ? "3.5vw" : "1vw",
      "&:hover": {
        backgroundColor: "#f0f3fa",
      },
    }),
  };

  const form = useRef();
  const [captcha, setCaptcha] = useState("");
  const [userCaptchaInput, setUserCaptchaInput] = useState("");
  const [isCaptchaOpen, setIsCaptchaOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedStars, setSelectedStars] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptcha(`${num1} + ${num2}`);
  };

  const validateInputs = (formData) => {
    const name = formData.get("user_name").trim();
    const email = formData.get("user_email").trim();
    const phone = formData.get("user_phone").trim();

    if (name.length < 3 || !email.includes("@") || phone.length < 3) {
      alert("Please fill all fields correctly.");
      return false;
    }
    return true;
  };

  const sendEmailAfterVerification = async (e) => {
    e.preventDefault();
    console.log("Form submitted");

    const formData = new FormData(form.current);
    if (!validateInputs(formData)) return;
    setIsCaptchaOpen(true);
  };

  const handleCheckboxChange = (event) => {
    setIsSubscribed(event.target.checked);
  };

  const verifyCaptchaAndSendEmail = async () => {
    const captchaValues = captcha.split(" + ").map(Number);
    const correctCaptchaAnswer = captchaValues[0] + captchaValues[1];

    if (parseInt(userCaptchaInput) !== correctCaptchaAnswer) {
      alert("Captcha verification failed. Please try again.");
      setUserCaptchaInput("");
      generateCaptcha();
      return;
    }

    setIsLoading(true);
    try {
      const formData = new FormData(form.current);

      const emailData = {
        sender: {
          name: formData.get("user_email"),
          email: "washyourstuffmrk@gmail.com",
        },
        to: [{ email: "miyllc3@gmail.com" }],
        subject: `New Feedback from ${formData.get("user_name")}`,
        htmlContent: `
        <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #000;">
          <p>Hello <strong style="color: #0000FF;">WYS Team</strong>,</p>
          <p>You got a New Feedback from <strong>${formData.get("user_name")}</strong></p>
          <table style="width: 70%; border-collapse: collapse; border: 1px solid #000;">
            <tr>
              <td style="width: 30%; border: 1px solid #000; padding: 8px;"><strong>Name</strong></td>
              <td style="border: 1px solid #000; padding: 8px;">${formData.get("user_name")}</td>
            </tr>
            <tr>
              <td style="width: 30%; border: 1px solid #000; padding: 8px;"><strong>Email</strong></td>
              <td style="border: 1px solid #000; padding: 8px;">${formData.get("user_email")}</td>
            </tr>
            <tr>
              <td style="width: 30%; border: 1px solid #000; padding: 8px;"><strong>Phone Number</strong></td>
              <td style="border: 1px solid #000; padding: 8px;">${formData.get("user_phone")}</td>
            </tr>
            <tr>
              <td style="width: 30%; border: 1px solid #000; padding: 8px;"><strong>Branch</strong></td>
              <td style="border: 1px solid #000; padding: 8px;"> ${formData.get("user_branch") || "Not specified"}</td>
            </tr>
            <tr>
              <td style="width: 30%; border: 1px solid #000; padding: 8px;"><strong>Rating</strong></td>
              <td style="border: 1px solid #000; padding: 8px;"> ${formData.get("user_stars") || "Not rated"}</td>
            </tr>
            <tr>
              <td style="width: 30%; border: 1px solid #000; padding: 8px;"><strong>Feedback</strong></td>
              <td style="border: 1px solid #000; padding: 8px;"> ${formData.get("user_feedback")}</td>
            </tr>
            <tr>
              <td style="width: 30%; border: 1px solid #000; padding: 8px;"><strong>Subscribed</strong></td>
              <td style="border: 1px solid #000; padding: 8px;">${formData.get("subscription_message")}</td>
            </tr>
          </table>
          <p>Best wishes,<br><strong style="color: #0000FF;">WYS Team</strong></p>
        </div>
      `,
      };

      await axios.post("https://api.brevo.com/v3/smtp/email", emailData, {
        headers: {
          "Content-Type": "application/json",
          "api-key": "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",
        },
      });

      if (isSubscribed) {
        await axios.post(
          "https://api.brevo.com/v3/contacts",
          {
            email: formData.get("user_email"),
            attributes: { FIRSTNAME: formData.get("user_name"), SMS: formData.get("user_phone") },
            listIds: [4],
            updateEnabled: true,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": "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",
            },
          }
        );
      }

      setIsSuccess(true);

      form.current.reset();
      setSelectedBranch(null);
      setSelectedStars(null);
      setIsSubscribed(false);
      setCaptcha("");
      setUserCaptchaInput("");
      generateCaptcha();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorCode = error.response.data.code;
        if (errorCode === "duplicate_parameter") {
          alert("You are already subscribed.");
        } else {
          alert("Failed to subscribe. Please check your input and try again.");
        }
      } else {
        console.error("Error during subscription:", error);
        alert("An unexpected error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const branchOptions = [
    { value: "GrandPrairie", label: "Grand Prairie" },
    { value: "Grapevine", label: "Grapevine" },
    { value: "Keller", label: "Keller" },
  ];

  const starOptions = [
    {
      value: `1 Star`,
      label: (
        <span>
          <img src={GoldStar} alt="Gold Star" />
          <img src={GrayStar} alt="Gray Star" />
          <img src={GrayStar} alt="Gray Star" />
          <img src={GrayStar} alt="Gray Star" />
          <img src={GrayStar} alt="Gray Star" />
        </span>
      ),
    },
    {
      value: `2 Stars`,
      label: (
        <span>
          <img src={GoldStar} alt="Gold Star" />
          <img src={GoldStar} alt="Gold Star" />
          <img src={GrayStar} alt="Gray Star" />
          <img src={GrayStar} alt="Gray Star" />
          <img src={GrayStar} alt="Gray Star" />
        </span>
      ),
    },
    {
      value: `3 Stars`,
      label: (
        <span>
          <img src={GoldStar} alt="Gold Star" />
          <img src={GoldStar} alt="Gold Star" />
          <img src={GoldStar} alt="Gold Star" />
          <img src={GrayStar} alt="Gray Star" />
          <img src={GrayStar} alt="Gray Star" />
        </span>
      ),
    },
    {
      value: `4 Stars`,
      label: (
        <span>
          <img src={GoldStar} alt="Gold Star" />
          <img src={GoldStar} alt="Gold Star" />
          <img src={GoldStar} alt="Gold Star" />
          <img src={GoldStar} alt="Gold Star" />
          <img src={GrayStar} alt="Gray Star" />
        </span>
      ),
    },
    {
      value: `5 Stars`,
      label: (
        <span>
          <img src={GoldStar} alt="Gold Star" />
          <img src={GoldStar} alt="Gold Star" />
          <img src={GoldStar} alt="Gold Star" />
          <img src={GoldStar} alt="Gold Star" />
          <img src={GoldStar} alt="Gold Star" />
        </span>
      ),
    },
  ];

  return (
    <div className="FeedBackS2">
      <div className="FeedBackS2P1">Feedback Form</div>
      <div className="FeedBackS2P2">
        <form ref={form} onSubmit={sendEmailAfterVerification}>
          <div className="FeedBackS2P2V">
            <label className="input">
              <input className="input_field_Feed input_1" type="text" placeholder="Name *" name="user_name" required />
            </label>

            <label className="input">
              <input className="input_field_Feed input_1" type="text" placeholder="Phone *" name="user_phone" required />
            </label>

            <label className="input">
              <input className="input_field_Feed input_1" type="text" placeholder="Email *" name="user_email" required />
            </label>

            <label className="input">
              <Select
                options={branchOptions}
                placeholder="Which location is this feedback for?"
                name="user_branch"
                styles={customSelectStyles}
                value={selectedBranch}
                onChange={setSelectedBranch}
              />
            </label>

            {/* Star Rating Dropdown with react-select */}
            <label className="input">
              <Select
                options={starOptions}
                placeholder="How well did we do? --> 1,2,3,4,5 stars."
                name="user_stars"
                styles={customSelectStyles}
                value={selectedStars}
                onChange={setSelectedStars}
              />
            </label>

            <label className="input">
              <textarea
                className="input_field_Feed BigFeedBack"
                placeholder="Please share with us any positive or negative feedback."
                name="user_feedback"
                required
              />
            </label>

            <div className="solutionQuestion">
              <input type="checkbox" id="subscribeCheckbox" className="FeedBackCheckBox" checked={isSubscribed} onChange={handleCheckboxChange} />
              <label htmlFor="subscribeCheckbox">Sign up for our email list for updates, promotions, and more.</label>
            </div>

            <input type="hidden" name="user_subscribe" value={isSubscribed ? "Yes" : "No"} />

            <input type="hidden" name="subscription_message" value={isSubscribed ? "User has subscribed to the newsletter." : "sorry user not"} />

            <div>
              <button className="FeedBackButton" type="submit">
                Send Us <img src={HomeS3Right} alt="" className="FeedBackButtonImg" />
              </button>
            </div>
          </div>
        </form>
      </div>

      {/* Captcha Pop-up */}
      {isCaptchaOpen && (
        <div className="captchaModal">
          <div className="captchaModalContent">
            {isLoading ? (
              <p>Loading...</p>
            ) : isSuccess ? (
              <>
                <p>Thanks, your email has been sent, and one of our team members will contact you soon.</p>
                <button onClick={() => setIsCaptchaOpen(false)}>Close</button>
              </>
            ) : (
              <>
                <h3>Verify you are human</h3>
                <p>What is {captcha}?</p>
                <input type="text" placeholder="Enter the answer" value={userCaptchaInput} onChange={(e) => setUserCaptchaInput(e.target.value)} required />
                <button onClick={verifyCaptchaAndSendEmail}>Verify and Send</button>
                <button
                  onClick={() => {
                    setIsCaptchaOpen(false); // Close modal
                    setUserCaptchaInput(""); // Clear input
                    generateCaptcha(); // Generate a fresh CAPTCHA
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
function FeedBackS3() {
  return (
    <>
      <div className="FeedBackS3">
        <div className="FeedBackS3P1">Wash Your Stuff locations</div>
        {/*  */}
        <div className="HomeS3P2">
          {/*  */}
          <div className="HomeS3P2B1">
            <img src={HomeS3First} alt="" className="HomeS3P2B1Img" />
            <h1 className="HomeS3P2B1H1">grand prairie</h1>
            <button className="HomeS3P2B1H2">NEW LOCATION</button>
            <Link to="/GrandPrairie">
              <button className="HomeS3P2B1Button">
                Wash services <img src={HomeS3Right} alt="" className="HomeS3RightImg" />
              </button>
            </Link>
          </div>
          {/*  */}
          <div className="HomeS3P2B1">
            <img src={HomeS3Second} alt="" className="HomeS3P2B1Img" />
            <h1 className="HomeS3P2B1H1">Grapevine</h1>
            <Link to="/Grapevine">
              <button className="HomeS3P2B1Button">
                Wash services <img src={HomeS3Right} alt="" className="HomeS3RightImg" />
              </button>
            </Link>
          </div>
          {/*  */}
          <div className="HomeS3P2B1">
            <img src={HomeS3Third} alt="" className="HomeS3P2B1Img" />
            <h1 className="HomeS3P2B1H1">Keller</h1>
            <Link to="/Keller">
              <button className="HomeS3P2B1Button">
                Wash services <img src={HomeS3Right} alt="" className="HomeS3RightImg" />
              </button>
            </Link>
          </div>
          {/*  */}
        </div>
      </div>
    </>
  );
}
