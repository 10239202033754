// Grand_Prairie Big Cart Image
import Grand_Prairie_BCart from "../../../Assets/images/Branches/GrandPrairie/Grand_Prairie_BCart.webp";
import Grand_Prairie_BCartMob from "../../../Assets/images/Branches/GrandPrairie/Grand_Prairie_BCartMob.webp";

// Grand_Prairie Small Cart Images
import Grand_PrairieSCart1 from "../../../Assets/images/Branches/GrandPrairie/Grand_PrairieSCart1.webp";
import Grand_PrairieSCart2 from "../../../Assets/images/Branches/GrandPrairie/Grand_PrairieSCart2.webp";
import Grand_PrairieSCart3 from "../../../Assets/images/Branches/GrandPrairie/Grand_PrairieSCart3.webp";
import Grand_PrairieSCart4 from "../../../Assets/images/Branches/GrandPrairie/Grand_PrairieSCart4.webp";
import Grand_PrairieSCart5 from "../../../Assets/images/Branches/GrandPrairie/Grand_PrairieSCart5.webp";
import Grand_PrairieSCart6 from "../../../Assets/images/Branches/GrandPrairie/Grand_PrairieSCart6.webp";

// Grand_Prairie Big Cart Data
export const grandPrairieDataBigCart = {
  title: "grand prairie SERVICES",
  subtitle: (
    <>
      smart & clean<span className="SpanBiggerMore">,</span> on a budget routine{" "}
    </>
  ),
  serviceTitle: "Self Service Car Wash",
  price: "Starting at $3",
  leftItems: [
    "Tire and Wheel Cleaning",
    "Engine Cleaning",
    <>
      Window and Chrome Cleaning <br /> (Low-Pressure Presoak)
    </>,
    <>
      {" "}
      High-Pressure Soap <br /> (High Pressure Presoak)
    </>,
    "High-Pressure Rinse",
  ],
  rightItems: ["Foam Brush", "Triple-Foam Polish", "Clear-Coat Protectant/Wax Coating", "Spot-Free Rinse", "Air Dryer"],
  imageSrc: Grand_Prairie_BCart,
  imageSrcMob: Grand_Prairie_BCartMob,
  imageAlt: "Grand Prairie self service car wash",
};

// Grand_Prairie Small Cart Data
export const grandPrairieSmallCartsData = [
  {
    serviceTitle: (
      <>
        Petit 360-i <br /> Touch-Free Automatic
      </>
    ),
    price: "Best wash $22",
    leftItems: [
      "Carnauba Wax",
      "Ceramic Coating",
      "Side & Underbody Blast",
      "Tire Cleaner",
      "Double-Foaming Presoak",
      "Bug Cleaner",
      "Triple High Pressure Wash",
      "Triple Foam Polish",
      "Spot-Free Rinse",
      "Maximum Blower",
    ],
    imageSrc: Grand_PrairieSCart1,
    imageAlt: "Grand Prairie Best touch free car wash",
    height: "33vw",
  },
  {
    serviceTitle: (
      <>
        Petit 360-i <br /> Touch-Free Automatic
      </>
    ),
    price: "Better wash $18",
    leftItems: [
      "Side & Underbody Blast",
      "Triple Foam Polish",
      "Tire Cleaner",
      "Double-Foaming Presoak",
      "Bug Cleaner",
      "Double High Pressure Wash",
      "Spot-Free Rinse",
      "Maximum Blower",
    ],
    imageSrc: Grand_PrairieSCart2,
    imageAlt: "Grand Prairie Better touch free car wash",
    height: "33vw",
  },
  {
    serviceTitle: (
      <>
        Petit 360-i <br /> Touch-Free Automatic
      </>
    ),
    price: "Good wash $12",
    leftItems: ["Double-Foaming Presoak", "Clear Coat Protectant", "Bug Cleaner", "High Pressure Wash", "Spot-Free Rinse", "Maximum Blower"],
    imageSrc: Grand_PrairieSCart3,
    imageAlt: "Grand Prairie Good touch free car wash",
    height: "33vw",
  },
  {
    serviceTitle: (
      <>
        Petit 360-i <br /> Touch-Free Automatic
      </>
    ),
    price: "Basic wash $8",
    leftItems: ["Foaming Presoak", "High Pressure Wash", "Spot-Free Rinse", "Maximum Blower"],
    imageSrc: Grand_PrairieSCart4,
    imageAlt: "Grand Prairie Basic touch free car wash",
    height: "25vw",
  },
  {
    serviceTitle: "Vending Products",
    price: "Starting at $1",
    leftItems: ["Disposable Towels", "Microfiber Towels", "Air Fresheners", "Armor All Products", "Variety of Other CarCare", "Cold Drinks", "Snacks"],
    imageSrc: Grand_PrairieSCart5,
    imageAlt: "Grand Prairie vending machine",
    height: "25vw",
  },
  {
    serviceTitle: "Vacuum Services",
    price: "Starting at $2",
    leftItems: ["Power Vacuum", "Shampoo Vacuum", "Fragrance Vacuum", "Air Vacuum"],
    imageSrc: Grand_PrairieSCart6,
    imageAlt: "Grand Prairie car wash Vacuum",
    height: "25vw",
  },
];
