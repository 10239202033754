import React from "react";
import "./Reviews.css";
import HomeS3FirstMob from "../../Assets/images/Home/HomeS3FirstMob.webp";
import HomeS3SecondMob from "../../Assets/images/Home/HomeS3SecondMob.webp";
import HomeS3ThirdMob from "../../Assets/images/Home/HomeS3ThirdMob.webp";
import ReviewsGoogle from "../../Assets/svg/Reviews/ReviewsGoogle.svg";
import ReviewsYelp from "../../Assets/svg/Reviews/ReviewsYelp.svg";

export default function Reviews() {
  return (
    <>
      <div className="Reviews">
        <RS1 />
      </div>
    </>
  );
}

function RS1() {
  return (
    <>
      <div className="RS1">
        <div className="RS1P1">
          <div className="RS1P1T1">Reviews</div>
          <div className="RS1P1T2">
            Share your thoughts!
            <br /> and we'll make sure your ride shines.
          </div>
        </div>
        {/*  */}
        <div className="RS1P2">
          {/*  */}
          <div className="RS1P2B1">
            <img src={HomeS3FirstMob} alt="gpl self wash car" className="RS1P2B1Img" />
            <h1 className="RS1P2B1H1">grand prairie</h1>
            <button className="RS1P2B1H2">NEW LOCATION</button>
            {/*  */}
            <div className="RS1P2B1V">
              <div className="RS1P1T2 RS1P2B1V1">REVIEW Grand Prairie on</div>
              <div className="RS1P2B1V2">
                <a href="https://g.page/r/CbYKYpjsrYP-EAI/review" target="Blank">
                  <img src={ReviewsGoogle} alt="" />
                </a>
                <a
                  href="https://www.yelp.com/writeareview/biz/Q1ybQ0rAEQd8ixDZAqoCPQ?return_url=%2Fbiz%2FQ1ybQ0rAEQd8ixDZAqoCPQ&review_origin=biz-details-war-button"
                  target="blank"
                >
                  <img src={ReviewsYelp} alt="" />
                </a>
              </div>
            </div>
            {/*  */}
          </div>
          {/*  */}
          <div className="RS1P2B1">
            <img src={HomeS3SecondMob} alt="grl self wash car" className="RS1P2B1Img" />
            <h1 className="RS1P2B1H1">Grapevine</h1>
            {/*  */}
            <div className="RS1P2B1V">
              <div className="RS1P1T2 RS1P2B1V1">REVIEW Grapevine on</div>
              <div className="RS1P2B1V2">
                <a href="https://g.page/r/CRyMQ4wQnx3dEAE/review" target="blank">
                  <img src={ReviewsGoogle} alt="" />
                </a>
                <a
                  href="https://www.yelp.com/writeareview/biz/W7livx8Hz_l5ml6Sa-KT2w?return_url=%2Fbiz%2FW7livx8Hz_l5ml6Sa-KT2w&review_origin=biz-details-war-button"
                  target="blank"
                >
                  <img src={ReviewsYelp} alt="" />
                </a>
              </div>
            </div>
            {/*  */}
          </div>
          {/*  */}
          <div className="RS1P2B1">
            <img src={HomeS3ThirdMob} alt="Klrl self wash car" className="RS1P2B1Img" />
            <h1 className="RS1P2B1H1">Keller</h1>
            {/*  */}
            <div className="RS1P2B1V">
              <div className="RS1P1T2 RS1P2B1V1">REVIEW Keller on</div>
              <div className="RS1P2B1V2">
                <a href="https://g.page/r/Ca6U9ne2LTPyEAE/review" target="blank">
                  <img src={ReviewsGoogle} alt="" />
                </a>
                <a
                  href="https://www.yelp.com/writeareview/biz/7e2Ys_Y40w3ahE4G_CpD0Q?return_url=%2Fbiz%2F7e2Ys_Y40w3ahE4G_CpD0Q&review_origin=biz-details-war-button"
                  target="blank"
                >
                  <img src={ReviewsYelp} alt="" />
                </a>
              </div>
            </div>
            {/*  */}
          </div>
          {/*  */}
        </div>
      </div>
    </>
  );
}
