import React from "react";
import "./Blog.css";
import BlogData1Img from "../../Assets/images/Blog/BlogData1.webp";
import BlogData2Img from "../../Assets/images/Blog/BlogData2.webp";
import BlogData3Img from "../../Assets/images/Blog/BlogData3.webp";
import Blog from "../../Assets/hooks/Reuse_Component/BlogBox/BlogBox";
import BlogS3Icon from "../../Assets/svg/Blog/BlogS3Icon.svg";
import { Link } from "react-router-dom";
import RightIcon from "../../Assets/svg/Home/RightIcon.svg";

export default function BlogContent() {
  return (
    <>
      <div className="BlogContainer">
        <BlogS1 />
        <BlogS2 />
        <BlogS3 />
      </div>
    </>
  );
}

function BlogS1() {
  return (
    <>
      <div className="BlogS1">
        <div className="BlogS1P1">Welcome to the Wash Your Stuff blog</div>
        <div className="BlogS1P2">
          Your car deserves <br /> the royal treatment
        </div>
        <div className="BlogS1P3">
          Here<span className="SpanBigger">,</span> you'll find helpful tips<span className="SpanBigger">,</span> expert advice
          <span className="SpanBigger">,</span> and the latest news on our services and promotions. <br />
          Stay informed and keep your vehicle looking its best with our expert <br /> guidance.
        </div>
      </div>
    </>
  );
}

// blog section 2

export const BlogData1 = [
  {
    Image: BlogData1Img,
    ImgUrl: "/Self-Service",
    Alt: "B Self service car wash",
    Date: "25 April 2023",
    Head: (
      <>
        Why Self-Service or <br /> Touch-Free?
      </>
    ),
    Text: (
      <>
        If your goal is to clean your car with the least amount of damage to your vehicle keep reading… If your goal is a clean car at any cost then you can
        stop reading and find yourself a local express friction tunnel wash!"<span className="SpanBigger">.</span>
      </>
    ),
    Button: "/Self-Service",
    CreatedBy: "Wash Your Stuff Team",
  },
];
export const BlogData2 = [
  {
    Image: BlogData2Img,
    ImgUrl: "/A-Dog-Grooming",
    Alt: "B pet wash service",
    Date: "7 December 2022",
    Head: (
      <>
        The Benefits Of Using A Dog <br /> Grooming Bathtub
      </>
    ),

    Text: (
      <>
        "Bathing your dog(s) is an essential part of keeping them healthy. That said<span className="SpanBigger">,</span> sometimes this can be a little
        overwhelming and stressful. That’s where having a dog grooming bathtub can be of assistance. 
      </>
    ),
    Button: "/A-Dog-Grooming",
    CreatedBy: "Wash Your Stuff Team",
  },
];
export const BlogData3 = [
  {
    Image: BlogData3Img,
    ImgUrl: "/great-car-wash",
    Alt: "B car wash center",
    Date: "22 August 2022",
    Head: (
      <>
        5 Benefits for a great <br /> car wash
      </>
    ),
    Text: "The benefits of regularly washing your vehicle go far beyond cosmetic appeal. Having a clean car is part of your vehicle’s routine maintenance. ",
    Button: "/great-car-wash",
    CreatedBy: "Wash Your Stuff Team",
  },
];

function BlogS2() {
  return (
    <>
      <div className="BlogS2">
        <div className="BlogS2P4">
          <Blog BlogData={BlogData1} />
          <Blog BlogData={BlogData2} />
          <Blog BlogData={BlogData3} />
        </div>
      </div>
    </>
  );
}
function BlogS3() {
  return (
    <>
      <div className="BlogS3">
        <div className="BlogS3P1">
          <img src={BlogS3Icon} alt="" className="BlogS3P1Icon" />
        </div>
        <div className="BlogS3P2">Our Location</div>
        <div className="BlogS3P3">Find Us Near You</div>
        <div className="BlogS3P4">
          With convenient locations in Grand prairie<span className="SpanBigger">,</span> Grapevine and Keller<span className="SpanBigger">,</span> we’re always
          close by <br /> when you need a quick, quality clean. Visit us anytime—day or night!
        </div>
        <div className="BlogS3P5">
          <Link to="/Locations">
            Explore Our Locations
            <img src={RightIcon} alt="" className="BlogS3P5Icon" />
          </Link>
        </div>
      </div>
    </>
  );
}
